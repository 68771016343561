import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import GlobalStyles from '@mui/material/GlobalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';

import { PageHeader } from "../components/page-header";

export const HomePage = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/pipeline",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  return (
    <>
    <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
    <CssBaseline />
    <PageHeader/>
    <Container maxWidth="xl" component="main" sx={{mt: .5, px: 0}}>
      <Stack 
        justifyContent="flex-start"
        alignItems="center"
        pt={7}
        sx={{backgroundColor: '#ebeef6', minHeight: '100dvh'}}
      >
        <Box
          sx={{
            bgcolor: '#fff',
            borderRadius: '12px',
            boxShadow: 24,
            mb: 2,
            p: 3,
            maxWidth: 375,
          }}>
            <Stack alignItems="center" justifyContent="center"> 
              <img src='/summit_logo.svg' width='225px' alt="Summit Funding Advisors" />
              <Stack alignItems="center" pt={2} direction="row">
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 700,
                    color: 'inherit',
                    textDecoration: 'none',
                  }}
                >
                  JourneyTrack&trade;
                </Typography>
                <Chip color="primary" size="small" label="BETA" />
              </Stack>
              
              <Typography variant="body1" fontWeight="700" pt={2} pb={4} textAlign={"center"}>
                Charting your mortgage journey with exceptional service.
              </Typography>
              <Button variant="contained" size='medium' onClick={handleLogin}>LOGIN</Button>
            </Stack>
        </Box>
      </Stack>
      </Container>
      </>
   );
}
