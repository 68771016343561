import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Chip from '@mui/material/Chip';

export default function LoanPostClosing(props) {
  const [statusCount, setStatusCount] = useState(0);

  // let isBrokeredLoan = props.brokeredLoan.trim() !== "";
  let finalCloPkgRcvdDate = props.finalCloPkgRcvdDate;

  useEffect(() => {
    checkConditions();
  },[]);

  function checkConditions() {
    checkDate(finalCloPkgRcvdDate);
  }

  function checkDate(date) {
    if(date.length === 0) {
      setStatusCount((prevValue) => prevValue + 1);
    }
  }

  return (
    <>
    <Accordion sx={{boxShadow:"none", backgroundColor:"#f1f2f3"}} disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="disclosure-content"
        id="disclosure-header"
        sx={{bgcolor:"#d3d9ee", fontWeight:"700"}}
      >
        POST CLOSING
        {statusCount > 0 ? (
          <Chip label={statusCount} size='small' color='error' sx={{ml:1}} />
        ) : (
          <CheckCircleIcon color="success" sx={{ml:1}} />
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Typography level="body1" fontWeight="700" sx={{pt: .75, display: "flex", alignItems: "center"}}>
          Final Closing Package Received: {finalCloPkgRcvdDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {finalCloPkgRcvdDate.length === 0 ? "Not Received" : finalCloPkgRcvdDate}
        </Typography>
      </AccordionDetails>
    </Accordion>
    </>
  );
}