import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function LoanInfo(props) {
  let loanNumber = props.loanNumber;
  let borrLastName = props.borrLastName;
  let borrFirstName = props.borrFirstName;
  let coBorrLastName = props.coBorrLastName;
  let coBorrFirstName = props.coBorrFirstName;
  let address = props.address;
  let city = props.city;
  let state = props.state;
  let loanAmount = props.loanAmount;
  let loanType = props.loanType;
  let loanPurpose = props.loanPurpose;
  let lender = props.lender;
  let selfEmployed = props.selfEmployed;
  let brokeredLoan = props.brokeredLoan;
  let loanOfficer = props.loanOfficer;
  let lockDate = props.lockDate;
  let lockExpDate = props.lockExpDate;
  let estCloseDate = props.estCloseDate;
  let lastMilestone = props.lastMilestone;
  let nextMilestone = props.nextMilestone;
  let appraisalCont = props.appraisalCont;
  let financingCont = props.financingCont;
  let borrContactPrefCellPhone = props.borrContactPrefCellPhone;
  let borrContactPrefHomePhone = props.borrContactPrefHomePhone;
  let borrContactPrefWorkPhone = props.borrContactPrefWorkPhone;
  let borrContactPrefHomeEmail = props.borrContactPrefHomeEmail;
  let borrContactPrefWorkEmail = props.borrContactPrefWorkEmail;
  let borrContactPrefTextMsg = props.borrContactPrefTextMsg;
  let coBorrContactPrefCellPhone = props.coBorrContactPrefCellPhone;
  let coBorrContactPrefHomePhone = props.coBorrContactPrefHomePhone;
  let coBorrContactPrefWorkPhone = props.coBorrContactPrefWorkPhone;
  let coBorrContactPrefHomeEmail = props.coBorrContactPrefHomeEmail;
  let coBorrContactPrefWorkEmail = props.coBorrContactPrefWorkEmail;
  let coBorrContactPrefTextMsg = props.coBorrContactPrefTextMsg;

  if (lastMilestone === "Funded") {
    nextMilestone = "Completion"
  }

  function getDaysRemaining(date) {
    let totalDays = "";

    if (lastMilestone === "Funded") {
      totalDays = "Closed";
    } else {
      if (date !== "") {
        let currentDate = new Date();
        let closingDate = new Date(date);
        //console.log("date currentDate: ", currentDate);
        //console.log("date closingDate: ", closingDate);
        // calculating total time difference
        let timeDifference = closingDate.getTime() - currentDate.getTime();
        totalDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
        //console.log("date totalDays: ", totalDays);
        if (totalDays < 0) { 
          totalDays = totalDays * -1 + ' days passed date';
        }
        //console.log("Number of days till close: ", totalDays);
      } else {
        totalDays = "TBD";
      }
    }
    
    return totalDays;
  }

  function getContactPrefs(borrower) {
    var contactPrefsArray = [];
    let contactID = 0;
    if (borrower === "borr") {
      if (borrContactPrefCellPhone.trim() !== "") {
        contactPrefsArray.push({id: contactID++, pref: 'Cell Phone'});
      }
      if (borrContactPrefTextMsg.trim() !== "") {
        contactPrefsArray.push({id: contactID++, pref: 'Text Message'});
      }
      if (borrContactPrefHomePhone.trim() !== "") {
        contactPrefsArray.push({id: contactID++, pref: 'Home Phone'});
      }
      if (borrContactPrefWorkPhone.trim() !== "") {
        contactPrefsArray.push({id: contactID++, pref: 'Work Phone'});
      }
      if (borrContactPrefHomeEmail.trim() !== "") {
        contactPrefsArray.push({id: contactID++, pref: 'Home Email'});
      }
      if (borrContactPrefWorkEmail.trim() !== "") {
        contactPrefsArray.push({id: contactID++, pref: 'Work Email'});
      }
    } else if (borrower === "coborr") {
      if (coBorrContactPrefCellPhone.trim() !== "") {
        contactPrefsArray.push({id: contactID++, pref: 'Cell Phone'});
      }
      if (coBorrContactPrefTextMsg.trim() !== "") {
        contactPrefsArray.push({id: contactID++, pref: 'Text Message'});
      }
      if (coBorrContactPrefHomePhone.trim() !== "") {
        contactPrefsArray.push({id: contactID++, pref: 'Home Phone'});
      }
      if (coBorrContactPrefWorkPhone.trim() !== "") {
        contactPrefsArray.push({id: contactID++, pref: 'Work Phone'});
      }
      if (coBorrContactPrefHomeEmail.trim() !== "") {
        contactPrefsArray.push({id: contactID++, pref: 'Home Email'});
      }
      if (coBorrContactPrefWorkEmail.trim() !== "") {
        contactPrefsArray.push({id: contactID++, pref: 'Work Email'});
      }
    }
    return(
        contactPrefsArray.map((contact) => (
          <Typography key={contact.id} level="body1" sx={{pl: 1}}>
            {contact.pref}
          </Typography>
        ))
    );
  }

  return (
    <>
    <Box sx={{bgcolor:"#d3d9ee", p:1}}>
      <Typography level="body1" fontWeight="700" textcolor="#ffffff">
        LOAN INFORMATION
      </Typography>
    </Box>
    <Box sx={{py: .75, px: 1}}>
      <Typography level="body1" fontWeight="700">
        Borrower Name: 
      </Typography>
      <Typography level="body1" sx={{pl: 1}}>
        {borrLastName}, {borrFirstName}
      </Typography>
      {coBorrLastName !== " " || coBorrFirstName !== " " ? (
        <>
        <Typography level="body1" fontWeight="700" sx={{pt: 1}}>
          Co-Borrower Name: 
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {coBorrLastName}, {coBorrFirstName}
        </Typography>
        </>
      ) : null }
      <Typography level="body1" fontWeight="700" sx={{pt: 1}}>
        Est Closing Date: 
      </Typography>
      <Typography level="body1" sx={{pl: 1}}>
        {estCloseDate.length === 0 ? 'TBD' : estCloseDate}
      </Typography>
      <Typography level="body1" fontWeight="700" sx={{pt: 1}}>
        Days to Closing: 
      </Typography>
      <Typography level="body1" sx={{pl: 1}}>
        {lastMilestone === "Completed" || lastMilestone === "Funded"  ? 'Closed' : getDaysRemaining(estCloseDate)}
      </Typography>
      <Typography level="body1" fontWeight="700" sx={{pt: 1}}>
        Lock Date: 
      </Typography>
      <Typography level="body1" sx={{pl: 1}}>
        {lockDate.length === 0 ? 'Not Locked' : lockDate}
      </Typography>
      <Typography level="body1" fontWeight="700" sx={{pt: 1}}>
        Lock Expiration Date: 
      </Typography>
      <Typography level="body1" sx={{pl: 1}}>
        {lockExpDate.length === 0 ? 'Not Locked' : lockExpDate}
      </Typography>
      {appraisalCont !== "" || financingCont !== "" ? (
        <Box sx={{bgcolor:"#fff4e5", mt: 1, pb: 1, px: 1, borderRadius: 3}}>
          {appraisalCont !== "" && (
            <>
            <Typography level="body1" fontWeight="700" sx={{pt: 1}}>
              Appraisal Contingency Date: 
            </Typography>
            <Typography level="body1" sx={{pl: 1}}>
              {appraisalCont}
            </Typography>
            </>
          )}
          {financingCont !== "" && (
            <>
            <Typography level="body1" fontWeight="700" sx={{pt: 1}}>
              Financing Contingency Date: 
            </Typography>
            <Typography level="body1" sx={{pl: 1}}>
              {financingCont}
            </Typography>
            </>
          )}
        </Box>
      ) : null }
      <Box sx={{bgcolor:"#5a5a70", mt: 1, pb: 1, px: 1, borderRadius: 3}}>
        <Typography level="body1" fontWeight="700" sx={{pt: 1, color:"#ffffff"}}>
          Last Completed Milestone: 
        </Typography>
        <Typography level="body1" sx={{pl: 1, color:"#ffffff"}}>
          {lastMilestone}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1, color:"#ffffff"}}>
            Next Expected Milestone: 
        </Typography>
        <Typography level="body1" sx={{pl: 1, color:"#ffffff"}}>
          {nextMilestone}
        </Typography>
      </Box>
      <Accordion sx={{mt: 1, boxShadow:"none", backgroundColor:"#f1f2f3"}} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id={loanNumber+'loan'}
          sx={{bgcolor:"#cdcdd4", fontWeight:"700"}}
        >
          MORE DETAILS
        </AccordionSummary>
        <AccordionDetails>
        <Typography level="body1" fontWeight="700" sx={{pt: .75}}>
          Loan Number: 
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {loanNumber}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1}}>
          Subject Property Address: 
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {address}<br></br>
          {city}, {state}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1}}>
          Loan Amount: 
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          ${loanAmount}
        </Typography>
        <Typography level="body1" fontWeight="700" noWrap={true} sx={{pt: 1}}>
          Loan Type: 
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {loanType}
        </Typography>
        <Typography level="body1" fontWeight="700" noWrap={true} sx={{pt: 1}}>
          Loan Purpose: 
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {loanPurpose}
        </Typography>
        <Typography level="body1" fontWeight="700" noWrap={true} sx={{pt: 1}}>
          Lender (Program): 
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {lender}
        </Typography>
        <Typography level="body1" fontWeight="700" noWrap={true} sx={{pt: 1}}>
          Borrower Self Employed: 
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {selfEmployed === "Y" ? "Yes" : "No"}
        </Typography>
        <Typography level="body1" fontWeight="700" noWrap={true} sx={{pt: 1}}>
          Brokered Loan: 
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {brokeredLoan !== " " ? "Yes" : "No"}
        </Typography>
        <Typography level="body1" fontWeight="700" noWrap={true} sx={{pt: 1}}>
          Borrower Contact Preference: 
        </Typography>
        <Box>
          {getContactPrefs('borr')}
        </Box>
        {coBorrLastName !== " " || coBorrFirstName !== " " ? (
          <>
          <Typography level="body1" fontWeight="700" sx={{pt: 1}}>
            Co-Borrower Contact Preference: 
          </Typography>
          <Box>
            {getContactPrefs('coborr')}
          </Box>
          </>
        ) : null }
        <Typography level="body1" fontWeight="700" noWrap={true} sx={{pt: 1}}>
          Loan Originator: 
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {loanOfficer}
        </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
    </>
  );
}