import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function LoanUnderwriting(props) {
  const [statusCount, setStatusCount] = useState(0);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #5a5a70',
    borderRadius: '12px',
    boxShadow: 24,
    p: 2,
    overflowY: 'scroll'
  };

  let condApprvDate = props.condApprvDate;
  let condOrderDate = props.condOrderDate;
  let condRcvdDate = props.condRcvdDate;
  let apprvDate = props.apprvDate;
  let condListAll = props.condListAll;
  let condListFulfilled = props.condListFulfilled;
  let condListNotFulfilled = props.condListNotFulfilled;
  let condListOpenCount = parseInt(props.condListOpenCount, 10);
  let condListFulfilledCount = parseInt(props.condListFulfilledCount, 10);

  useEffect(() => {
    checkConditions();
  },[]);

  function checkConditions() {
    checkDate(apprvDate);
  }

  function checkDate(date) {
    if(date.length === 0) {
      setStatusCount((prevValue) => prevValue + 1);
    }
  }

  return (
    <>
    <Accordion sx={{boxShadow:"none", backgroundColor:"#f1f2f3"}} disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="disclosure-content"
        id="disclosure-header"
        sx={{bgcolor:"#d3d9ee", fontWeight:"700"}}
      >
        UNDERWRITING
        {statusCount > 0 ? (
          <Chip label={statusCount} size='small' color='error' sx={{ml:1}} />
        ) : (
          <CheckCircleIcon color="success" sx={{ml:1}} />
        )}
      </AccordionSummary>
      <AccordionDetails>
        {condListAll !== "" && (
          <Box sx={{my: .75, boxShadow:"none"}}>
            <Button variant="contained" size='small' onClick={handleOpen}>View Conditions</Button>
            <Chip label={condListOpenCount} size='small' color="error" sx={{ml:1}} />
          </Box>
        )}
        <Typography level="body1" fontWeight="700" sx={{display: "flex", alignItems: "center"}}>
          Conditionally Approved: {condApprvDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {condApprvDate.length === 0 ? "Not Sent" : condApprvDate}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          Conditions Requested: {condOrderDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {condOrderDate.length === 0 ? "Not Sent" : condOrderDate}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          Conditions Received: {condRcvdDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {condRcvdDate.length === 0 ? "Not Received" : condRcvdDate}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          Approved: {apprvDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {apprvDate.length === 0 ? "Waiting Approval" : apprvDate}
        </Typography>
      </AccordionDetails>
    </Accordion>

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Conditions
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{fontWeight:"700"}}>Condition</TableCell>
                  <TableCell sx={{fontWeight:"700"}} align="center">Fulfilled</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {condListOpenCount > 0 && (
                  condListNotFulfilled.split('\n').map((str, i) => (
                    <TableRow
                      key={i}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">{str.substring(0, str.length - 2)}</TableCell>
                      <TableCell align="center"><ErrorIcon color="error" sx={{ml:1}} /></TableCell>
                    </TableRow>
                  ))
                )}
                {condListFulfilledCount > 0 && (
                  condListFulfilled.split('\n').map((str, i) => (
                    <TableRow
                      key={i}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">{str.substring(0, str.length - 2)}</TableCell>
                      <TableCell align="center"><CheckCircleIcon color="success" sx={{ml:1}} /></TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Typography>
      </Box>
    </Modal>
    </>
  );
}