import React from "react";

import GlobalStyles from '@mui/material/GlobalStyles';
import CssBaseline from '@mui/material/CssBaseline';

import { PageHeader } from "../components/page-header";

export const CallbackPage = () => {
  return (
    <>
    <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
    <CssBaseline />
    <PageHeader />
    </>
  );
};
