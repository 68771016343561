import React from "react";

import GlobalStyles from '@mui/material/GlobalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { PageHeader } from "../components/page-header";

export const NotFoundPage = () => {
  return (
    <>
    <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
    <CssBaseline />
    <PageHeader/>
    <Container maxWidth="xl" component="main" sx={{mt: .5, px: 0}}>
      <Stack 
          justifyContent="flex-start"
          alignItems="center"
          pt={7}
        >
          <Typography variant="h4">
            Not found
          </Typography>
      </Stack>
    </Container>
    </>
  );
};
