import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

export const PageLoader = () => {

  return (
    <Stack 
      justifyContent="flex-start"
      alignItems="center"
      pt={7}
      sx={{minHeight: '100dvh'}}
    >
      <CircularProgress />
    </Stack>
  );
};
