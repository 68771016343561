import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Chip from '@mui/material/Chip';

export default function LoanDisclosure(props) {
  const [statusCount, setStatusCount] = useState(0);

  let appDate = props.appDate;
  let leDueDate = props.leDueDate;
  let eConsentDate = props.eConsentDate;
  let intentProceedDate = props.intentProceedDate;
  let creditOrderDate = props.creditOrderDate;
  let creditRcvdDate = props.creditRcvdDate;
  let earliestFeeCollDate = props.earliestFeeCollDate;
  let earliestCloseDate = props.earliestCloseDate;
  let estCloseDate = props.estCloseDate;
  let leSentDate = props.leSentDate;
  let leRcvdDate = props.leRcvdDate;
  let leRevSentDate = props.leRevSentDate;
  let leRevRcvdDate = props.leRevRcvdDate;
  let ssplSentDate = props.ssplSentDate;
  let submitProcDate = props.submitProcDate;
  //let role = props.role;

  useEffect(() => {
    checkConditions();
  },[]);

  function checkConditions() {
    checkDate(intentProceedDate);
    checkDate(creditRcvdDate);
    checkDate(leRcvdDate);
    checkDate(submitProcDate);
  }

  function checkDate(date) {
    if(date.length === 0) {
      setStatusCount((prevValue) => prevValue + 1);
    }
  }

  return (
    <>
    <Accordion sx={{boxShadow:"none", backgroundColor:"#f1f2f3"}} disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="disclosure-content"
        id="disclosure-header"
        sx={{bgcolor:"#d3d9ee", fontWeight:"700"}}
      >
        DISCLOSURE DESK
        {statusCount > 0 ? (
          <Chip label={statusCount} size='small' color='error' sx={{ml:1}} />
        ) : (
          <CheckCircleIcon color="success" sx={{ml:1}} />
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Typography level="body1" fontWeight="700" sx={{pt: .75}}>
          Application Date: 
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {appDate.length === 0 ? "Not Set" : appDate}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1}}>
          Earliest Fee Collection: 
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {earliestFeeCollDate.length === 0 ? "Not Set" : earliestFeeCollDate}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1}}>
          Earliest Closing: 
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {earliestCloseDate.length === 0 ? "Not Set" : earliestCloseDate}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1}}>
          Estimated Closing: 
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {estCloseDate.length === 0 ? "Not Set" : estCloseDate}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          eConsent: {eConsentDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {eConsentDate.length === 0 ? "Not Received" : eConsentDate}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          LE Due:
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {leDueDate.length === 0 ? "Not Set" : leDueDate}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          LE Sent: {leSentDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {leSentDate.length === 0 ? "Not Sent" : leSentDate}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          LE Received: {leRcvdDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {leRcvdDate.length === 0 ? "Not Received" : leRcvdDate}
        </Typography>
        {leRevSentDate.length !== 0 && (
          <>
          <Typography level="body1" fontWeight="700" sx={{pt: 1}}>
            Revised LE Sent:
          </Typography>
          <Typography level="body1" sx={{pl: 1}}>
            {leRevSentDate.length === 0 ? "Not Sent" : leRevSentDate}
          </Typography>
          <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
            Revised LE Received: {leRevRcvdDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
          </Typography>
          <Typography level="body1" sx={{pl: 1}}>
            {leRevRcvdDate.length === 0 ? "Not Received" : leRevRcvdDate}
          </Typography>
          </>
        )}
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          Intent to Proceed: {intentProceedDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {intentProceedDate.length === 0 ? "Not Received" : intentProceedDate}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          Credit Ordered: {creditOrderDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {creditOrderDate.length === 0 ? "Not Ordered" : creditOrderDate}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          Credit Received: {creditRcvdDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {creditRcvdDate.length === 0 ? "Not Received" : creditRcvdDate}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          SSPL Sent: {ssplSentDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {ssplSentDate.length === 0 ? "Not Sent" : ssplSentDate}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          Submitted to Processing: {submitProcDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {submitProcDate.length === 0 ? "Not Submitted" : submitProcDate}
        </Typography>
      </AccordionDetails>
    </Accordion>
    </>
  );
}