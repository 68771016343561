import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { PageHeaderAccount } from './page-header-account';

export const PageHeader = () => {

  return (
    <AppBar position="static" color="secondary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Large view */}
          <Stack direction="row" alignItems="center" justifyContent={'space-between'} sx={{ width: '100%', marginTop: '3px', display: { xs: 'none', md: 'flex' } }}>
            <Box>
              <img src='/summit_logo_web_lg.png' width='96px' alt='Summit Funding Advisors logo'/>
            </Box>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography
                variant="h5"
                noWrap
                component="a"
                sx={{
                  fontWeight: 700,
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                JourneyTrack&trade;
              </Typography>
              <Chip color="primary" size="small" label="BETA" />
            </Stack>
            <PageHeaderAccount />
          </Stack>
          
          {/* <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}

          {/* Small view */}
          <Stack direction="row" justifyContent={'space-between'} sx={{ width: '100%', marginTop: '3px', display: { xs: 'flex', md: 'none' } }}>
            <Box>
              <img src='/summit_logo_web.png' width={68} alt='Summit Funding Advisors logo'/>
            </Box>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography
                variant="h6"
                noWrap
                component="a"
                sx={{
                  fontWeight: 700,
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                JourneyTrack&trade;
              </Typography>
              <Chip color="primary" size="small" label="BETA" />
            </Stack>
            <PageHeaderAccount size="small" />
          </Stack>

        </Toolbar>
      </Container>
    </AppBar>
  );
}