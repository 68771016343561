import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Chip from '@mui/material/Chip';

export default function LoanProcessing(props) {
  const [statusCount, setStatusCount] = useState(0);

  let loanType = props.loanType;
  let titleOrderDate = props.titleOrderDate;
  let titleRcvdDate = props.titleRcvdDate;
  let appraisalWaiverDate = props.appraisalWaiverDate;
  let appraisalOrderDate = props.appraisalOrderDate;
  let appraisalRcvdDate = props.appraisalRcvdDate;
  let appraisalAckOrderDate = props.appraisalAckOrderDate;
  let appraisalAckRcvdDate = props.appraisalAckRcvdDate;
  let floodRcvdDate = props.floodRcvdDate;
  let floodCert = props.floodCert;
  let hoiRcvdDate = props.hoiRcvdDate;
  let uspsRcvdDate = props.uspsRcvdDate;
  let voeFinalRcvdDate = props.voeFinalRcvdDate;
  let selfEmployed = props.selfEmployed;
  let transOrderDate = props.transOrderDate;
  let transRcvdDate = props.transRcvdDate;
  let agencyCaseNum = props.agencyCaseNum;
  let coeOrderDate = props.coeOrderDate;
  let coeRcvdDate = props.coeRcvdDate;
  let submitUnderDate = props.submitUnderDate;
  let lastMilestone = props.lastMilestone;

  if(coeOrderDate.length === 0 && coeRcvdDate.length !== 0) {
    coeOrderDate = "Ordered";
  } 

  useEffect(() => {
    checkConditions();
  },[]);

  function checkConditions() {
    checkDate(titleRcvdDate);
    checkDate(hoiRcvdDate);
    checkDate(uspsRcvdDate);
    checkDate(voeFinalRcvdDate);
    checkDate(submitUnderDate);
    checkAppraisal();
    checkSelfEmployed();
    checkFlood();
    checkFHAStatus();
    checkVAStatus();
  }

  function checkDate(date) {
    if(date.length === 0) {
      setStatusCount((prevValue) => prevValue + 1);
    }
  }

  function checkAppraisal() {
    if(appraisalWaiverDate.length === 0 && appraisalRcvdDate.length === 0) {
      //console.log("Appraisal needed");
      setStatusCount((prevValue) => prevValue + 1);
    }
  }

  function checkSelfEmployed() {
    if(selfEmployed === "Y" && transRcvdDate.length === 0) {
      //console.log("Transcripts needed");
      setStatusCount((prevValue) => prevValue + 1);
    }
  }

  function checkFlood() {
    if(floodRcvdDate.length === 0 && floodCert.length === 0) {
      //console.log("Flood needed");
      setStatusCount((prevValue) => prevValue + 1);
    }
  }

  function checkFHAStatus() {
    if(loanType === "FHA") {
      if(agencyCaseNum.length === 0) {
        //console.log("FHA needed");
        setStatusCount((prevValue) => prevValue + 1);
      }
    }
  }

  function checkVAStatus() {
    if(loanType === "VA") {
      if(agencyCaseNum.length !== 0 && coeRcvdDate.length === 0) {
        //console.log("VA needed");
        setStatusCount((prevValue) => prevValue + 1);
      }
    }
  }

  return (
    <>
    <Accordion sx={{boxShadow:"none", backgroundColor:"#f1f2f3"}} disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="disclosure-content"
        id="disclosure-header"
        sx={{bgcolor:"#d3d9ee", fontWeight:"700"}}
      >
        PROCESSING
        {statusCount === 0 || lastMilestone === "Funded" ? (
          <CheckCircleIcon color="success" sx={{ml:1}} />
        ) : (
          <Chip label={statusCount} size='small' color='error' sx={{ml:1}} />
        )}
      </AccordionSummary>
      <AccordionDetails>
        {(titleRcvdDate.length !== 0 && titleOrderDate.length === 0) ? (
           <></>
        ) : (
          <>
          <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          Title Ordered: {titleOrderDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
          </Typography>
          <Typography level="body1" sx={{pl: 1}}>
            {titleOrderDate.length === 0 ? "Not Ordered" : titleOrderDate}
          </Typography>
          </>
        )}
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          Title Received: {titleRcvdDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {titleRcvdDate.length === 0 ? "Not Received" : titleRcvdDate}
        </Typography>
        {appraisalWaiverDate.length !== 0 ? (
          <>
          <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
            Appraisal Waiver Received: <CheckCircleIcon color="success" sx={{ml:1}} />
          </Typography>
          <Typography level="body1" sx={{pl: 1}}>
            {appraisalWaiverDate}
          </Typography>
          </>
        ) : (
          <>
          {(appraisalRcvdDate.length !== 0 && appraisalOrderDate.length === 0) ? (
            <></>
          ) : (
            <>
            <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
              Appraisal Ordered: {appraisalOrderDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
            </Typography>
            <Typography level="body1" sx={{pl: 1}}>
              {appraisalOrderDate.length === 0 ? "Not Ordered" : appraisalOrderDate}
            </Typography>
            </>
          )}
          <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
            Appraisal Received: {appraisalRcvdDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
          </Typography>
          <Typography level="body1" sx={{pl: 1}}>
            {appraisalRcvdDate.length === 0 ? "Not Received" : appraisalRcvdDate}
          </Typography>
          {(appraisalRcvdDate.length !== 0 && appraisalAckOrderDate.length === 0) ? (
            <></>
          ) : (
            <>
            <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
              Appraisal Ack Ordered: {appraisalAckOrderDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
            </Typography>
            <Typography level="body1" sx={{pl: 1}}>
              {appraisalAckOrderDate.length === 0 ? "Not Ordered" : appraisalAckOrderDate}
            </Typography>
            </>
          )}
          <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
            Appraisal Ack Received: {appraisalAckRcvdDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
          </Typography>
          <Typography level="body1" sx={{pl: 1}}>
            {appraisalAckRcvdDate.length === 0 ? "Not Ordered" : appraisalAckRcvdDate}
          </Typography>
          </>
        )}
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          Flood Received: {floodRcvdDate.length === 0 && floodCert.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        {floodRcvdDate.length === 0 && floodCert.length === 0 && (
          <Typography level="body1" sx={{pl: 1}}>
            Not Received
          </Typography>
        )}
        {floodCert.length !== 0 && (
          <Typography level="body1" sx={{pl: 1}}>
            Flood Certification Received
          </Typography>
        )}
        {floodRcvdDate.length !== 0 && (
          <Typography level="body1" sx={{pl: 1}}>
            {floodRcvdDate}
          </Typography>
        )}
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          HOI Received: {hoiRcvdDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {hoiRcvdDate.length === 0 ? "Not Received" : hoiRcvdDate}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          USPS Received: {uspsRcvdDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {uspsRcvdDate.length === 0 ? "Not Received" : uspsRcvdDate}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          VOE Final Received: {voeFinalRcvdDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {voeFinalRcvdDate.length === 0 ? "Not Received" : voeFinalRcvdDate}
        </Typography>
        {selfEmployed === "Y" && (
          <>
          <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
            Transcripts Ordered: {transOrderDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
          </Typography>
          <Typography level="body1" sx={{pl: 1}}>
            {transOrderDate.length === 0 ? "Not Ordered" : transOrderDate}
          </Typography>
          <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
            Transcripts Received: {transRcvdDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
          </Typography>
          <Typography level="body1" sx={{pl: 1}}>
            {transRcvdDate.length === 0 ? "Not Received" : transRcvdDate}
          </Typography>
          </>
        )}
        {loanType === "FHA" && (
          <>
          <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
            FHA Case Number: {agencyCaseNum.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
          </Typography>
          <Typography level="body1" sx={{pl: 1}}>
            {agencyCaseNum.length === 0 ? "TBD" : agencyCaseNum}
          </Typography>
        </>
        )}
        {loanType === "VA" && (
          <>
          <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
            VA Case Number: {agencyCaseNum.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
          </Typography>
          <Typography level="body1" sx={{pl: 1}}>
            {agencyCaseNum.length === 0 ? "TBD" : agencyCaseNum}
          </Typography>
          <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
            COE (VA) Ordered: {coeOrderDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
          </Typography>
          <Typography level="body1" sx={{pl: 1}}>
            {coeOrderDate.length === 0 ? "Not Ordered" : coeOrderDate}
          </Typography>
          <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
            COE (VA) Received: {coeRcvdDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
          </Typography>
          <Typography level="body1" sx={{pl: 1}}>
            {coeRcvdDate.length === 0 ? "Not Received" : coeRcvdDate}
          </Typography>
          </>
        )}
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          Submitted to Underwriting: {submitUnderDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {submitUnderDate.length === 0 ? "Not Submitted" : submitUnderDate}
        </Typography>
      </AccordionDetails>
    </Accordion>
    </>
  );
}