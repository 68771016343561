import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Chip from '@mui/material/Chip';

export default function LoanClosing(props) {
  const [statusCount, setStatusCount] = useState(0);

  let appraisalRcvdDate = props.appraisalRcvdDate;
  let titleRcvdDate = props.titleRcvdDate;
  let hoiRcvdDate = props.hoiRcvdDate;
  let cdSentDate = props.cdSentDate;
  let cdRcvdDate = props.cdRcvdDate;
  let revCdSentDate = props.revCdSentDate;
  let revCdRcvdDate = props.revCdRcvdDate;
  let cloPkgSent = props.cloPkgSent;
  let fundingDate = props.fundingDate;
  let lastMilestone = props.lastMilestone;

  useEffect(() => {
    checkConditions();
  },[]);

  function checkConditions() {
    checkDate(cdRcvdDate);
    checkDate(cloPkgSent);
    checkDate(fundingDate);
  }

  function checkDate(date) {
    if(date.length === 0) {
      setStatusCount((prevValue) => prevValue + 1);
    }
  }

  return (
    <>
    <Accordion sx={{boxShadow:"none", backgroundColor:"#f1f2f3"}} disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="disclosure-content"
        id="disclosure-header"
        sx={{bgcolor:"#d3d9ee", fontWeight:"700"}}
      >
        CLOSING
        {statusCount > 0 ? (
          <Chip label={statusCount} size='small' color='error' sx={{ml:1}} />
        ) : (
          <CheckCircleIcon color="success" sx={{ml:1}} />
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Typography level="body1" fontWeight="700" sx={{display: "flex", alignItems: "center"}}>
          CD Ready: {appraisalRcvdDate.length === 0 || titleRcvdDate.length === 0 || hoiRcvdDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          CD Sent: {cdSentDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {cdSentDate.length === 0 ? "Not Sent" : cdSentDate}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          CD Received: {cdRcvdDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {cdRcvdDate.length === 0 ? "Not Received" : cdRcvdDate}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          Final CD Sent: {revCdSentDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {revCdSentDate.length === 0 ? "Not Sent" : revCdSentDate}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          Final CD Received: {revCdRcvdDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {revCdRcvdDate.length === 0 ? "Not Received" : revCdRcvdDate}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          Closing Package Sent: {cloPkgSent.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {cloPkgSent.length === 0 ? "Not Sent" : cloPkgSent}
        </Typography>
        <Typography level="body1" fontWeight="700" sx={{pt: 1, display: "flex", alignItems: "center"}}>
          Funded: {fundingDate.length === 0 ? <ErrorIcon color="error" sx={{ml:1}} /> : <CheckCircleIcon color="success" sx={{ml:1}} />}
        </Typography>
        <Typography level="body1" sx={{pl: 1}}>
          {fundingDate.length === 0 ? "Not Funded" : fundingDate}
        </Typography>
      </AccordionDetails>
    </Accordion>
    </>
  );
}