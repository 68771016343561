import React from "react";
import GlobalStyles from '@mui/material/GlobalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import { useAuth0 } from "@auth0/auth0-react";
import { Route, Routes } from "react-router-dom";
import { AuthenticationGuard } from "./components/authentication-guard";
import { CallbackPage } from "./pages/callback-page";
import { PageLoader } from "./components/page-loader";
import { HomePage } from "./pages/home-page";
import PipelinePage from "./pages/pipeline-page";
import { NotFoundPage } from "./pages/not-found-page";

export const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
        <CssBaseline />
        <Container maxWidth="xl" component="main" sx={{mt: .5, px: 0}}>
          <Stack 
            justifyContent="flex-start"
            alignItems="center"
            pt={7}
            sx={{minHeight: '100dvh'}}
          >
            <PageLoader />
          </Stack>
        </Container>
      </>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/callback" element={<CallbackPage />} />
      <Route
        path="/pipeline"
        element={<AuthenticationGuard component={PipelinePage} />}
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
