import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonIcon from '@mui/icons-material/Person';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';

export const PageHeaderAccount = () => {
  const { isAuthenticated } = useAuth0();
  const { user } = useAuth0();
  const { logout} = useAuth0();
  const { loginWithRedirect } = useAuth0();
  
  let roles= '';
  let userRoles = '';

  if (user) {
    roles = user['https://loanview.sfaloans.com/roles'];
    userRoles = roles.map((role, i) => (
      <span key={role}>{role}{(roles.length - 1 !== i) && ', '}</span>
    ))
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/pipeline",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const getUserInitials = () => {
    let initials = user.name
      .split(' ')
      .map(word => word.charAt(0))
      .join('');
    return initials;
  };

  return (
    <>
    <Stack 
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{px: 1}}
    >
      <Box>
      {isAuthenticated ? <Avatar sx={{ width: 36, height: 36, bgcolor: '#556cd6' }}>{getUserInitials()}</Avatar> : <PersonIcon size="lg" />}
      </Box>

      <Box>
        <IconButton
          id="basic-button"
          aria-label="expand"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}>
          <KeyboardArrowDownIcon />
        </IconButton>
        {isAuthenticated ? (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem>
              <ListItemIcon>
                <PersonIcon size="sm" />
              </ListItemIcon>
              <ListItemText primary={user.name} secondary={userRoles}/>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon size="sm" />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
        ) : (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
          }}
          >
            <MenuItem onClick={handleLogin}>
              <ListItemIcon>
                <LoginIcon size="sm" />
              </ListItemIcon>
              <ListItemText>Login</ListItemText>
            </MenuItem>
          </Menu>
        )}
      </Box>
    </Stack>
    </>
  );
}
